<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    
    <div class="main about-page">
      <!-- Hero Section -->
      <div class="hero-section">        
        <div class="hero-content">
          <div class="hero-logo">
            <img src="/ssr/img/tiktok_voice_logo.svg" :title="$t('about.heroLogoTitle')" :alt="$t('about.heroLogoAlt')" height="60px" width="75px" class="top_left_logo" />
          </div>
          <h1>{{ $t('about.heroTitle') }}</h1>
          <p class="hero-subtitle">{{ $t('about.heroSubtitle') }}</p>
        </div>
      </div>

      <!-- Stats Section -->
      <section class="about-section stats-section">
        <h2>{{ $t('about.statsTitle') }}</h2>
        <p class="section-subtitle">{{ $t('about.statsSubtitle') }}</p>
        <div class="stats-grid">
          <div class="stat-item">
            <h3>50K+</h3>
            <p>{{ $t('about.statsActiveUsers') }}</p>
          </div>
          <div class="stat-item">
            <h3>1M+</h3>
            <p>{{ $t('about.statsVoicesGenerated') }}</p>
          </div>
          <div class="stat-item">
            <h3>100+</h3>
            <p>{{ $t('about.statsVoiceOptions') }}</p>
          </div>
        </div>
      </section>

      <!-- Mission Section -->
      <section class="about-section mission-section">
        <div class="section-content">
          <h2>{{ $t('about.missionTitle') }}</h2>
          <p>{{ $t('about.missionDescription') }}</p>
          <div class="mission-grid">
            <div class="mission-item">
              <div class="icon">🎯</div>
              <h4>{{ $t('about.missionAccessibilityTitle') }}</h4>
              <p>{{ $t('about.missionAccessibilityDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">💡</div>
              <h4>{{ $t('about.missionInnovationTitle') }}</h4>
              <p>{{ $t('about.missionInnovationDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">🤝</div>
              <h4>{{ $t('about.missionCommunityTitle') }}</h4>
              <p>{{ $t('about.missionCommunityDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">🌍</div>
              <h4>{{ $t('about.missionGlobalReachTitle') }}</h4>
              <p>{{ $t('about.missionGlobalReachDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">🔒</div>
              <h4>{{ $t('about.missionSecurityTitle') }}</h4>
              <p>{{ $t('about.missionSecurityDesc') }}</p>
            </div>
            <div class="mission-item">
              <div class="icon">📈</div>
              <h4>{{ $t('about.missionGrowthTitle') }}</h4>
              <p>{{ $t('about.missionGrowthDesc') }}</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Team Section -->
      <section class="about-section team-section">
        <h2>{{ $t('about.teamTitle') }}</h2>
        <p class="section-subtitle">{{ $t('about.teamSubtitle') }}</p>
        <div class="team-image-container">
          <img src="/ssr/img/team-photo.jpg" :alt="$t('about.teamPhotoAlt')" loading="lazy" fetchpriority="low" title="TikTok AI Voice Generator Team" class="team-photo" />
        </div>
        <div class="team-grid">
          <!-- Add team members as needed -->
        </div>
      </section>

      <!-- Contact Section -->
      <section class="about-section contact-section">
        <h2>{{ $t('about.contactTitle') }}</h2>
        <p class="section-subtitle">{{ $t('about.contactSubtitle') }}</p>
        <div class="contact-grid">
          <div class="contact-item">
            <div class="icon">✉️</div>
            <h4>{{ $t('about.contactEmailTitle') }}</h4>
            <a :href="sendmail">{{ $t('about.contactEmailAddress') }}</a>
          </div>
          <div class="contact-item">
            <div class="icon">𝕏</div>
            <h4>{{ $t('about.contactSocialTitle') }}</h4>
            <a href="https://x.com/aurthur711335" target="_blank" rel="noopener noreferrer">{{ $t('about.contactSocialHandle') }}</a>
          </div>
        </div>
      </section>
    </div>

    <div class="footer">
      <voice-footer></voice-footer>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import VoiceHeader from '../components/VoiceHeader.vue'

const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooterIndex.vue'))

export default {
  name: 'about',
  components: {
    VoiceHeader,
    VoiceFooter
  },
  head() {
    return {
      title: this.$t('about.headTitle'),
      keywords: this.$t('about.headKeywords'),
      description: this.$t('about.headDescription'),
      link: [
        { 
          rel: 'stylesheet', 
          href: '/ssr/css/second_style.css',
          id: 'second-style',
          media: 'all'
        }
      ]
    }
  },
  computed: {
    sendmail() {
      return `mailto:support@tiktokvoice.net`
    }
  }
}
</script>

<style scoped>
@import url('/ssr/css/about.css');

:root { --container-padding: 20px; --section-spacing: 100px; --grid-gap: 30px; --primary-color: #1d1d1f; --secondary-color: #86868b; --background-light: #f5f5f7; --background-white: #ffffff; --transition-default: all 0.4s ease; }
.container, .main, .about-page { margin: 0; padding: 0; width: 100vw; max-width: 100%; overflow-x: hidden; }
.container { width: 100%; min-height: 100vh; background: #ffffff; }
.main { width: 100%; }
.section-content { max-width: min(1400px, 100%); margin: 0 auto; padding: 0 var(--container-padding); box-sizing: border-box; }
.hero-section { position: relative; background: #ffffff; overflow: hidden; }
.hero-section h1 { font-size: clamp(48px, 6vw, 64px); font-weight: 700; color: var(--primary-color); margin-bottom: 20px; line-height: 1.2; letter-spacing: -0.02em; text-align: center; max-width: 800px; margin-left: auto; margin-right: auto; }
.hero-subtitle { font-size: clamp(20px, 2.5vw, 24px); color: var(--secondary-color); max-width: 600px; margin: 0 auto; line-height: 1.5; letter-spacing: 0; text-align: center; font-weight: 400; }
.hero-content { padding: 80px 20px; display: flex; flex-direction: column; align-items: center; justify-content: center; position: relative; z-index: 1; }
.hero-logo { display: flex; align-items: center; justify-content: center; gap: 15px; margin: 0 auto clamp(30px, 5vh, 60px); margin-bottom: 40px;  }
.hero-logo .top_left_logo { height: clamp(120px, 15vw, 180px); width: auto; }

.stats-section { display: flex; flex-direction: column; align-items: center; padding: 60px 20px; background: radial-gradient(circle at center, #302f2f 0%, #1a1a1a 50%, #000000 100%); }
.stats-section h2 { font-size: 48px; font-weight: 700; color: #ffffff; text-align: center; margin-bottom: 20px; letter-spacing: -0.5px; text-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
.stats-section .section-subtitle { font-size: 24px; color: rgba(255, 255, 255, 0.8); text-align: center; margin-bottom: 40px; letter-spacing: 0.2px; line-height: 1.4; max-width: 700px; margin-left: auto; margin-right: auto; text-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
.stats-section h2, .stats-section .section-subtitle { text-align: center; max-width: 800px; margin: 0 auto; }

.team-image-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 60px;
  padding: 0 var(--container-padding);
}

.team-photo {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) { 
  .hero-logo .top_left_logo { height: clamp(90px, 12vw, 120px); } 
  .hero-section h1 { font-size: clamp(36px, 5vw, 48px); margin-bottom: 16px; } .hero-subtitle { font-size: clamp(18px, 2vw, 20px); } .hero-content { padding: 60px 20px; } 
  .hero-subtitle { white-space: normal; }
  .about-section h2 { font-size: 36px; } 
  .section-subtitle { font-size: 18px; }
  .stats-section { padding: 80px 20px; gap: 30px; }
}

@media (max-width: 480px) { 
  .hero-logo .top_left_logo { height: clamp(75px, 10vw, 90px); } 
  .about-section h2 {font-size: 32px; font-weight: 600; color: var(--primary-color); text-align: center; margin-bottom: 20px; letter-spacing: -0.5px; }
  .section-subtitle { font-size: 21px; color: var(--secondary-color); text-align: center; margin-bottom: 60px; letter-spacing: 0.2px; line-height: 1.4; max-width: 700px; margin-left: auto; margin-right: auto; }
  .section-content p { text-align: center; max-width: 800px; margin: 0 auto 40px; font-size: 18px; line-height: 1.6; color: var(--secondary-color); }
  .stats-section { padding: 60px 20px; }
}
</style>
